import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface IGroupProgressJSON extends IStepSyncProgressJSON {
    stepsProgress: Array<IStepSyncProgressJSON>;
    progressStatusPending: number;
    progressStatusSuccess: number;
    progressStatusError: number;
}

export interface IGroupProgressOptions extends IStepSyncProgressOptions {
    stepsProgress: Array<StepSyncProgress>;
}

export class GroupProgress extends StepSyncProgress {
    public stepsProgress: Array<StepSyncProgress>;

    public get hasError(): boolean {
        return this.errors.length > 0 || this.stepsProgress.some((stepProgress) => stepProgress.hasError);
    }

    constructor(options: IGroupProgressOptions) {
        super(options);
        this.stepsProgress = options.stepsProgress;
    }

    get progressStatusPending(): number {
        const result = this.stepsProgress.reduce((previousValue, stepProgress) => previousValue + stepProgress.progressStatusPending, 0);

        return result;
    }

    get progressStatusSuccess(): number {
        const result = this.stepsProgress.reduce((previousValue, stepProgress) => previousValue + stepProgress.progressStatusSuccess, 0);

        return result;
    }

    get progressStatusError(): number {
        const result = this.stepsProgress.reduce((previousValue, stepProgress) => previousValue + stepProgress.progressStatusError, 0);

        return result;
    }

    public toJSON(): IGroupProgressJSON {
        return Object.assign(super.toJSON(), this, {
            stepsProgress: this.stepsProgress.map((stepProgress) => stepProgress.toJSON()),
            progressStatusPending: this.progressStatusPending,
            progressStatusSuccess: this.progressStatusSuccess,
            progressStatusError: this.progressStatusError
        });
    }
}
